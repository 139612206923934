import * as React from 'react';
import { builder, BuilderComponent } from '@builder.io/react'
import { graphql } from 'gatsby'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SEO from "../components/SEO/SEO";


builder.init('f26a2f0bdfb442a396cf2eb9819e227b')

const MyComponent = (props) => {
  const content = props.data?.allBuilderModels.onePage?.content;

  return (
  <>
    <SEO
    title="Sample"
    description="Sample Description" />
  <Navbar />
  <BuilderComponent
    content={content}
    model="page" />
  <Footer />
  </>
  )
}

export default MyComponent;      

export const query = graphql`
  query($path: String!) {
    allBuilderModels {
      onePage(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) { content }
    }
  }
`

// Register your components for use in the visual editor!
// https://www.builder.io/blog/drag-drop-react
const Heading = props => (
  <h1 className="my-heading">{props.title}</h1>
)
